<template>
  <div class="mt-2 pa-3">
    <notifications group="errors" class="mt-2" position="top center" />
    <v-row>
      <v-col cols="12" md="4">
        <div class="upload-btn-wrapper">
          <div class="temp-cover d-flex align-center justify-center">
            <div v-if="!dp">
              <v-icon class="mr-3" size="30">mdi-camera-plus</v-icon> Upload
              cover
              <input type="file" name="myfile" @change="getPic($event)" />
            </div>
            <div v-if="dp">
              <img
                :src="dp"
                width="100%"
                height="200px"
                class="img-fit"
                alt="display picture"
              />
            </div>
          </div>
        </div>
        <v-btn v-if="dp" outlined color="green" rounded>
          <div class="upload-btn-wrapper add-width">
            <div class="pa-1">
              <v-icon class="mr-1"> mdi-pencil-box </v-icon>
              Change Picture
              <input type="file" @change="getPic($event)" name="myfile" />
            </div>
          </div>
        </v-btn>
      </v-col>
      <v-col cols="12" md="8">
        <v-text-field
          label="Title"
          required
          solo
          v-model="title"
        ></v-text-field>
        <vue-editor
          placeholder="Start Writing!"
          :editorToolbar="customToolbar"
          class="mb-3"
          v-model="article"
        ></vue-editor>
        <v-row>
          <v-col cols="3">
            <div class="d-flex align-center" style="height: 100%">
              Embed Content
            </div>
          </v-col>
          <v-col cols="9">
            <v-select
              solo
              hide-details="auto"
              item-text="state"
              item-value="abbr"
              :items="contentEmbed"
              v-model="embedType"
              label="Content"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="embedType">
          <v-col cols="3">
            <div class="d-flex align-center" style="height: 100%">
              Link Content
            </div>
          </v-col>
          <v-col cols="9">
            <v-text-field
              label="Link Content"
              required
              solo
              v-model="linkEmbed"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <h4 class="mb-3">Category :</h4>
        <v-row>
          <v-col cols="8" md="4" v-if="listCategory">
            <v-select
              solo
              hide-details="auto"
              item-text="nama"
              item-value="nama"
              :items="listCategory"
              v-model="category"
              label="Pilih Kategory"
            ></v-select>
          </v-col>
          <v-col cols="8" md="4" v-if="opt">
            <v-select
              solo
              hide-details="auto"
              item-text="state"
              item-value="abbr"
              :items="opt"
              v-model="privacy"
              label="Privacy"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <ImageEmpty v-bind:dialogEmpty="dialogEmpty" @close="close" @next="next" />
    <footer class="_full-w d-flex justify-end">
      <v-btn rounded outlined color="green" @click="validate" v-if="!loading"
        ><v-icon class="mr-2">mdi-comment-check</v-icon>Save</v-btn
      >
      <v-btn rounded outlined color="green" v-if="loading"
        ><v-progress-circular
          indeterminate
          size="25"
          class="mr-2"
        ></v-progress-circular
        >Loading</v-btn
      >
    </footer>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { VueEditor } from "vue2-editor";
import { mapState } from "vuex";
import ImageEmpty from "../_base/Modal/imageEmpty.vue";

export default {
  name: "createArticle",
  components: {
    VueEditor,
    ImageEmpty
  },
  computed: {
    ...mapState({
      listCategory: state => state.library.category
    })
  },
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["link"],
        [{ color: [] }]
      ],
      opt: [
        { state: "public", abbr: "public" },
        { state: "private", abbr: "private" }
      ],
      contentEmbed: [
        { state: "Audio", abbr: "audio" },
        { state: "Video", abbr: "Video" }
      ],
      embedType: null,
      linkEmbed: "",
      pic: null,
      dp: null,
      title: "",
      article: "",
      category: "Psikologi Klinis",
      dialogEmpty: false,
      loading: false,
      privacy: "public"
    };
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    getCategory() {
      this.$store.dispatch("library/listCategory").then(data => {
        console.log(data);
      });
    },
    validate() {
      if (this.pic == null) {
        this.dialogEmpty = true;
      } else {
        this.postItem();
      }
    },
    next() {
      this.postItem();
      this.close();
    },
    close() {
      this.dialogEmpty = false;
    },
    getPic(e) {
      const file = e.target.files[0];
      const fr = new FileReader();
      fr.onload = f => {
        this.dp = f.target.result;
      };
      fr.readAsDataURL(file);
      this.pic = file;
    },
    postItem() {
      this.loading = true;
      let data = new FormData();
      let content = this.embedType == "audio" ? "audio" : "vidoe";
      data.append("nama_kategori", this.category);
      data.append("judul", this.title);
      data.append("isi", this.article);
      if (this.linkEmbed) {
        data.append(content, this.linkEmbed);
      }
      data.append("visible", this.privacy);
      data.append("objImage", this.pic);
      this.$store
        .dispatch("library/createArticle", data)
        .then(data => {
          console.log(data);
          this.loading = false;
          if (data.status == "failed") {
            let err = data.data.errors;
            console.log(err);
            for (let i in err) {
              this.alert("error", `${err[i][0]}`);
            }
          } else {
            Swal.fire({
              icon: "success",
              title: data.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000
            });
          }
          this.title = "";
          this.article = "";
          this.dp = null;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        });
    },

    alert(type, title) {
      this.$notify({
        group: "errors",
        type: type,
        title: title
      });
    }
  }
};
</script>

<style scoped>
.temp-cover {
  width: 100%;
  height: 200px;
  border: 1px #000 dotted;
}
</style>
