<template>
  <div class="mt-2 pa-3">
    <notifications group="errors" class="mt-2" position="top center" />
    <v-row>
      <v-col cols="12" md="4">
        <div class="upload-btn-wrapper">
          <div class="temp-cover d-flex align-center justify-center">
            <div v-if="!dp">
              <v-icon class="mr-3" size="30">mdi-camera-plus</v-icon> Upload
              cover
              <input type="file" name="myfile" @change="getPic($event)" />
            </div>
            <div v-if="dp">
              <img
                :src="dp"
                width="100%"
                height="200px"
                class="img-fit"
                alt="display picture"
              />
            </div>
          </div>
        </div>
        <v-btn v-if="dp" outlined color="green" rounded>
          <div class="upload-btn-wrapper add-width">
            <div class="pa-1">
              <v-icon class="mr-1"> mdi-pencil-box </v-icon>
              Change Picture
              <input type="file" @change="getPic($event)" name="myfile" />
            </div>
          </div>
        </v-btn>
      </v-col>
      <v-col cols="12" md="8">
        <v-text-field
          label="Title"
          v-model="title"
          required
          solo
        ></v-text-field>
        <v-text-field
          label="Author"
          v-model="author"
          required
          solo
        ></v-text-field>
        <vue-editor
          placeholder="Synopsis!"
          :editorToolbar="customToolbar"
          class="mb-3"
          v-model="article"
        ></vue-editor>

        <v-file-input solo v-model="file" label="File e-book"></v-file-input>

        <h4 class="mb-3">Category :</h4>
        <v-row>
          <v-col cols="12" md="6" v-if="fields">
            <v-select
              solo
              hide-details="auto"
              item-text="nama"
              item-value="id"
              :items="fields.data"
              label="Psikologi klinis"
              v-model="field"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              solo
              hide-details="auto"
              item-text="state"
              item-value="val"
              :items="opt"
              label="Public"
              v-model="privacy"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <ImageEmpty v-bind:dialogEmpty="dialogEmpty" @close="close" @next="next" />
    <footer class="_full-w d-flex justify-end mt-3">
      <v-btn rounded outlined color="green" @click="validate" v-if="!loading"
        ><v-icon class="mr-2">mdi-comment-check</v-icon>Save</v-btn
      >
      <v-btn rounded outlined color="green" v-if="loading"
        ><v-progress-circular
          indeterminate
          size="25"
          class="mr-2"
        ></v-progress-circular
        >Loading</v-btn
      >
    </footer>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { VueEditor } from "vue2-editor";
import { mapState } from "vuex";
import ImageEmpty from "../_base/Modal/imageEmpty.vue";

export default {
  name: "createEbook",
  components: {
    VueEditor,
    ImageEmpty
  },

  computed: {
    ...mapState({
      fields: state => state.fields.fields
    })
  },

  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ color: [] }],
        ["link"]
      ],
      pic: null,
      dp: null,
      title: "",
      article: "",
      bidang: "Psikologi Klinis",
      dialogEmpty: false,
      loading: false,
      privacy: "public",
      field: "",
      file: null,
      author: "",
      opt: [
        { state: "Public", abbr: "public" },
        { state: "Private", abbr: "private" }
      ]
    };
  },
  mounted() {
    this.getFields();
  },
  methods: {
    getFields() {
      this.$store.dispatch("fields/listFields");
    },
    validate() {
      if (this.pic == null) {
        this.dialogEmpty = true;
      } else {
        this.postItem();
      }
    },
    next() {
      this.postItem();
      this.close();
    },
    close() {
      this.dialogEmpty = false;
    },

    getPic(e) {
      const file = e.target.files[0];
      const fr = new FileReader();
      fr.onload = f => {
        this.dp = f.target.result;
      };
      fr.readAsDataURL(file);
      this.pic = file;
    },

    postItem() {
      this.loading = true;
      let data = new FormData();
      data.append("judul", this.title);
      data.append("author", this.author);
      data.append("synopsis", this.article);
      if (this.privacy == null) {
        data.append("visible", "public");
      } else {
        data.append("visible", this.privacy);
      }
      if (this.field == "") {
        data.append("bidang_id", 1);
      } else {
        data.append("bidang_id", this.field);
      }
      data.append("objFile", this.file);
      data.append("objImage", this.pic);
      this.$store
        .dispatch("library/createEbook", data)
        .then(data => {
          console.log(data);
          this.loading = false;
          if (data.status == "failed") {
            let err = data.data.errors;
            console.log(err);
            for (let i in err) {
              this.alert("error", `${err[i][0]}`);
            }
          } else {
            Swal.fire({
              icon: "success",
              title: data.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000
            });
          }
          this.dp = null;
          this.title = "";
          this.article = "";
          this.author = "";
          this.file = null;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },

    alert(type, title) {
      this.$notify({
        group: "errors",
        type: type,
        title: title
      });
    }
  }
};
</script>

<style scoped>
.temp-cover {
  width: 200px;
  height: 250px;
  border: 1px #000 dotted;
}
</style>
